<template>
  <div class="flex flex-col">
    <div class="flex flex-col">
      <h2>Kontoseaded</h2>
      <h4>Meiliaadress</h4>
      <span class="mb-4">{{ $store.state.userData.user.email }}</span>
      <h4>Nimi</h4>
      <span class="mb-4">{{
        $store.state.userData.user.display_username
      }}</span>
      <h4>Konto staatus</h4>
      <div class="flex flex-col mb-4">
        <span
          class="flex items-center"
          v-if="$store.state.userData.user.is_email_verified"
          ><span
            class="typcn typcn-tick bg-green rounded-full leading-none p-1 flex text-white mr-2"
          ></span
          >Aktiveeritud</span
        >
        <span
          class="flex items-center"
          v-if="!$store.state.userData.user.is_email_verified"
          ><span
            class="typcn typcn-times bg-danger rounded-full leading-none p-1 flex text-white mr-2"
          ></span
          >Aktiveerimata</span
        >
        <router-link
          to="/resend-activation"
          class="hover:bg-green hover:text-white bg-offwhite shadow rounded-full self-start font-medium px-3 py-1 mt-2"
          v-if="!$store.state.userData.user.is_email_verified"
          >Ei saanud aktiveerimismeili?</router-link
        >
      </div>
      <h4>Muuda parooli</h4>
      <router-link to="/reset-password" class="flex">
        <button class="alt-button-green">
          <span class="typcn typcn-edit icon"></span
          ><span class="label">Muuda parooli</span>
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>
